// NewProject.tsx

import React, { useState, useEffect } from 'react';
import './style/NewProject.css';
import axiosRequest from '../utils/axiosRequest';
import { Helmet } from 'react-helmet';

const questionText = [
  'What is the Name of your New Project?',
  'Enter a Short Description of Your Project',
  "What is your Project's Allotted Funding?",
  "What are your Project's Requirements?"
];

interface NewProjectProps {
  setSection: (section: string) => void;
  setSelectedProjectId: (projectId: string) => void;
  isDarkMode: boolean;
  fetchProjects: () => Promise<void>;
}

const NewProject: React.FC<NewProjectProps> = ({
  setSection,
  setSelectedProjectId,
  isDarkMode,
  fetchProjects
}) => {
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [projectFunding, setProjectFunding] = useState('');
  const [projectRequirements, setProjectRequirements] = useState('');
  const [step, setStep] = useState(1);

  const [displayedText, setDisplayedText] = useState<React.ReactNode>('');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const projectsURL = process.env.REACT_APP_PROJECTS_API_URL;
  if (!projectsURL) {
    throw new Error('Create Project API URL is not defined');
  }

  // Typewriter effect
  useEffect(() => {
    setDisplayedText('');
    let index = 0;
    const typingSpeed = 15;
    let timerId: NodeJS.Timeout;

    const type = () => {
      if (index <= questionText[step - 1].length) {
        setDisplayedText(
          <>
            {questionText[step - 1].substring(0, index)}
            <span className="typing-cursor">|</span>
          </>
        );
        index++;
        timerId = setTimeout(type, typingSpeed);
      } else {
        setDisplayedText(
          <>
            {questionText[step - 1]}
            <span className="typing-cursor">|</span>
          </>
        );
      }
    };

    type();

    return () => {
      clearTimeout(timerId);
    };
  }, [step]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    switch (step) {
      case 1:
        setProjectName(e.target.value);
        break;
      case 2:
        setProjectDescription(e.target.value);
        break;
      case 3:
        setProjectFunding(e.target.value);
        break;
      case 4:
        setProjectRequirements(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e?: React.FormEvent) => {
    if (e) e.preventDefault();
    setError(null);

    // Move through the steps if not yet finished
    if (step < 4) {
      setStep(step + 1);
      return;
    }

    // Step = 4 => time to create
    setLoading(true);

    try {
      // 1) Check how many projects already exist
      const existingProjectsResp = await axiosRequest(projectsURL, 'get');
      const existingProjects = existingProjectsResp.data;

      // 2) If user has 0 projects, set this new one to default
      let isDefault = false;
      if (existingProjects.length === 0) {
        isDefault = true;
      }

      // 3) Create the project
      const newProject = {
        name: projectName,
        description: projectDescription,
        funding: projectFunding,
        requirements: projectRequirements,
        is_default: isDefault,
      };

      const response = await axiosRequest(`${projectsURL}create/`, 'post', newProject);
      console.log('New Project Created:', response.data);

      // 5) Fetch projects again to update the navbar and default project
      await fetchProjects();
      
      // 4) Update our local selectedProjectId
      setSelectedProjectId(response.data.id)

      // 6) Navigate directly to ProjectDashboard
      setSection('ProjectDashboard');
    } catch (error: any) {
      console.error('Error creating project:', error);
      setError('An error occurred while creating the project. Please try again.');
      setLoading(false);
    }
  };

  const handleTextareaKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div className={`new-project-container ${isDarkMode ? 'dark' : 'light'}`}>
      <Helmet>
        <title>New Project</title>
      </Helmet>
      <div className="question-heading-container">
        <h1
          tabIndex={0}
          role="heading"
          aria-level={1}
          className={`question-heading ${isDarkMode ? 'dark' : 'light'}`}
        >
          {displayedText}
        </h1>
      </div>

      {!loading && error && (
        <div className="error-message">
          <p className={`${isDarkMode ? 'dark' : 'light'}`}>{error}</p>
        </div>
      )}

      {!loading && (
        <form className="new-project-form" onSubmit={handleSubmit}>
          {step === 1 && (
            <div className="input-container">
              <label htmlFor="project-name" className="visually-hidden">
                Project Name
              </label>
              <input
                id="project-name"
                type="text"
                value={projectName}
                onChange={handleInputChange}
                placeholder="Enter project name"
                required
                className={`${isDarkMode ? 'dark' : 'light'}`}
              />
              <button
                type="submit"
                className={`submit-button ${isDarkMode ? 'dark' : 'light'}`}
                aria-label="Next"
              >
                ➤
              </button>
            </div>
          )}

          {step === 2 && (
            <div className="input-container textarea-container">
              <label htmlFor="project-description" className="visually-hidden">
                Project Description
              </label>
              <textarea
                id="project-description"
                value={projectDescription}
                onChange={handleInputChange}
                onKeyDown={handleTextareaKeyDown}
                placeholder="Enter project description"
                required
                className={`${isDarkMode ? 'dark' : 'light'}`}
              />
              <button
                type="submit"
                className={`submit-button ${isDarkMode ? 'dark' : 'light'}`}
                aria-label="Next"
              >
                ➤
              </button>
            </div>
          )}

          {step === 3 && (
            <div className="input-container">
              <label htmlFor="project-funding" className="visually-hidden">
                Project Funding
              </label>
              <input
                id="project-funding"
                type="number"
                value={projectFunding}
                onChange={handleInputChange}
                placeholder="Enter funding number"
                required
                className={`${isDarkMode ? 'dark' : 'light'}`}
              />
              <button
                type="submit"
                className={`submit-button ${isDarkMode ? 'dark' : 'light'}`}
                aria-label="Next"
              >
                ➤
              </button>
            </div>
          )}

          {step === 4 && (
            <div className="input-container textarea-container">
              <label htmlFor="project-requirements" className="visually-hidden">
                Project Requirements
              </label>
              <textarea
                id="project-requirements"
                value={projectRequirements}
                onChange={handleInputChange}
                onKeyDown={handleTextareaKeyDown}
                placeholder="Enter project requirements"
                required
                className={`${isDarkMode ? 'dark' : 'light'}`}
              />
              <button
                type="submit"
                className={`submit-button ${isDarkMode ? 'dark' : 'light'}`}
                aria-label="Submit"
              >
                ➤
              </button>
            </div>
          )}
        </form>
      )}

      {loading && (
        <div className="loading-indicator">
          <p className={`${isDarkMode ? 'dark' : 'light'}`}>Creating project...</p>
        </div>
      )}
    </div>
  );
};

export default NewProject;
