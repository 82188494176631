import React, { useEffect, useState, useRef, useContext } from 'react';
import axiosRequest from '../utils/axiosRequest';
import './style/ProjectDashboard.css';
import { ProjectData, Document, Thread, StakeholderData } from '../types';
import Loading from './loading/Loading';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';

// 1) Import your contexts:
import { TechScoutingContext, StakeholderIdentificationContext } from '../ContextStore';

interface ProjectDashboardProps {
  isDarkMode: boolean;
  projectId: string | null;
  setSection: (section: string) => void; // For navigation
  setSelectedLibraryProjectId: (projectId: string | null) => void; // For navigating to ProjectDetail
  updateProjectInList?: (updatedProject: ProjectData) => void;
  removeProjectFromList?: (projectId: string) => void;
}

const ProjectDashboard: React.FC<ProjectDashboardProps> = ({
  isDarkMode,
  projectId,
  setSection,
  setSelectedLibraryProjectId,
  updateProjectInList,
  removeProjectFromList,
}) => {
  const [projectData, setProjectData] = useState<ProjectData | null>(null);

  // SSE-based results
  const [techScoutingResults, setTechScoutingResults] = useState<any[]>([]);
  const [stakeholderResults, setStakeholderResults] = useState<any[]>([]);

  // Project library & UI
  const [projectDocuments, setProjectDocuments] = useState<Document[]>([]);
  const [projectThreads, setProjectThreads] = useState<Thread[]>([]);
  const [miniActiveTab, setMiniActiveTab] = useState<'documents' | 'threads'>('documents');

  // Overall SSE loading + error
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // SSE
  const eventSourceRef = useRef<EventSource | null>(null);

  // Access contexts
  const techContext = useContext(TechScoutingContext);
  const stakeholderContext = useContext(StakeholderIdentificationContext);
  if (!techContext || !stakeholderContext) {
    throw new Error('TechScoutingContext and StakeholderIdentificationContext must be provided.');
  }
  const { setTechResponse } = techContext;
  const { setStakeholderResponse } = stakeholderContext;

  // API URLs
  const projectsURL = process.env.REACT_APP_PROJECTS_API_URL;
  const documentsURL = process.env.REACT_APP_DOCUMENTS_API_URL;
  const threadsURL = process.env.REACT_APP_THREADS_API_URL;
  const projectSupportURL = process.env.REACT_APP_PROJECT_SUPPORT_API_URL;
  if (!projectsURL || !documentsURL || !threadsURL || !projectSupportURL) {
    throw new Error('API URLs are not defined');
  }

  // ------------------ Inline-edit states ------------------
  const [editingName, setEditingName] = useState<boolean>(false);
  const [editingDescription, setEditingDescription] = useState<boolean>(false);
  const [editingRequirements, setEditingRequirements] = useState<boolean>(false);
  const [editingFunding, setEditingFunding] = useState<boolean>(false);
  const [editingTechnology, setEditingTechnology] = useState<boolean>(false);

  // Temporary local form values
  const [tempName, setTempName] = useState<string>('');
  const [tempDescription, setTempDescription] = useState<string>('');
  const [tempRequirements, setTempRequirements] = useState<string>('');
  const [tempFunding, setTempFunding] = useState<string>('');
  const [tempTechnology, setTempTechnology] = useState<string>('');

  const [updateError, setUpdateError] = useState<string | null>(null);
  const [updateSuccess, setUpdateSuccess] = useState<string | null>(null);

  // ------------------ ProjectSupport states ------------------
  const [psDocumentType, setPsDocumentType] = useState<string>('Type');
  const [psDocumentTopic, setPsDocumentTopic] = useState<string>('');
  const [psResponse, setPsResponse] = useState<string | null>(null);
  const [psLoading, setPsLoading] = useState<boolean>(false);
  const [psError, setPsError] = useState<string | null>(null);
  const [psErrorKey, setPsErrorKey] = useState<number>(0);

  const [psShowTypeDropdown, setPsShowTypeDropdown] = useState<boolean>(false);
  const [psDocuments, setPsDocuments] = useState<Document[]>([]);
  const [psSelectedDocumentId, setPsSelectedDocumentId] = useState<string | null>(null);
  const [psSelectedDocumentTitle, setPsSelectedDocumentTitle] = useState<string>('Library');
  const [psLibraryDropdownOpen, setPsLibraryDropdownOpen] = useState<boolean>(false);

  // Refs for project support
  const psTypeDropdownRef = useRef<HTMLDivElement>(null);
  const psErrorRef = useRef<HTMLDivElement>(null);
  const psSubmitButtonRef = useRef<HTMLButtonElement>(null);
  const psLibraryDropdownButtonRef = useRef<HTMLButtonElement>(null);
  const psTypeDropdownButtonRef = useRef<HTMLButtonElement>(null);
  const psTypeDropdownMenuRef = useRef<HTMLDivElement>(null);
  const psLibraryDropdownMenuRef = useRef<HTMLDivElement>(null);

  const psDocumentTypes = [
    'None',
    'Approach Analysis & Design Sprint Planning Report',
    'Innovation Request Review Report',
    'Technology Scouting Report',
    'Use Case Identification Report',
  ];

  // ------------------ Delete Project states ------------------
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const [deleteSuccess, setDeleteSuccess] = useState<string | null>(null);

  // If technology changes, we can re-fetch
  const [reloadTrigger, setReloadTrigger] = useState<number>(0);

  // ----------------------------------------------------------------
  // Generic patch function to update project fields
  // ----------------------------------------------------------------
  const handleUpdateProjectField = async (fieldsToUpdate: Partial<ProjectData>) => {
    if (!projectId) return;

    setUpdateError(null);
    setUpdateSuccess(null);

    try {
      const patchPayload = { ...fieldsToUpdate };
      const response = await axiosRequest(`${projectsURL}${projectId}/update/`, 'patch', patchPayload);

      if (response.data?.project) {
        const updatedProject = response.data.project;
        setProjectData((prev) => (prev ? { ...prev, ...updatedProject } : updatedProject));
        setUpdateSuccess('Project updated successfully.');

        if (fieldsToUpdate.name && updateProjectInList) {
          updateProjectInList(updatedProject);
        }
        if (fieldsToUpdate.technology) {
          setReloadTrigger((prev) => prev + 1);
        }
      }
    } catch (err: any) {
      console.error('Error updating project field:', err);
      if (err.response?.data?.error) {
        setUpdateError(err.response.data.error);
      } else {
        setUpdateError('Error updating project.');
      }
    }
  };

  // ------------------ Save/Cancel Handlers ------------------
  const saveName = () => {
    if (tempName.trim()) {
      handleUpdateProjectField({ name: tempName });
    }
    setEditingName(false);
  };
  const cancelName = () => {
    if (projectData) setTempName(projectData.name);
    setEditingName(false);
  };

  const saveDescription = () => {
    handleUpdateProjectField({ description: tempDescription });
    setEditingDescription(false);
  };
  const cancelDescription = () => {
    if (projectData) {
      setTempDescription(projectData.description);
    }
    setEditingDescription(false);
  };

  const saveRequirements = () => {
    handleUpdateProjectField({ requirements: tempRequirements });
    setEditingRequirements(false);
  };
  const cancelRequirements = () => {
    if (projectData) {
      setTempRequirements(projectData.requirements);
    }
    setEditingRequirements(false);
  };

  const saveFunding = () => {
    const parsedValue = parseFloat(tempFunding);
    if (!Number.isNaN(parsedValue)) {
      handleUpdateProjectField({ funding: parsedValue });
    }
    setEditingFunding(false);
  };
  const cancelFunding = () => {
    if (projectData) {
      setTempFunding(projectData.funding?.toString() || '');
    }
    setEditingFunding(false);
  };

  const saveTechnology = () => {
    handleUpdateProjectField({ technology: tempTechnology });
    setEditingTechnology(false);
  };
  const cancelTechnology = () => {
    if (projectData) {
      setTempTechnology(projectData.technology || '');
    }
    setEditingTechnology(false);
  };

  // Populate form fields when projectData changes
  useEffect(() => {
    if (projectData) {
      setTempName(projectData.name || '');
      setTempDescription(projectData.description || '');
      setTempRequirements(projectData.requirements || '');
      setTempTechnology(projectData.technology || '');
      setTempFunding(
        projectData.funding != null ? String(projectData.funding) : ''
      );
    }
  }, [projectData]);

  // ----------------------------------------------------
  // Fetch project library data (documents & threads)
  // ----------------------------------------------------
  const fetchProjectLibraryData = async (pid: string) => {
    try {
      const [docsResp, threadsResp] = await Promise.all([
        axiosRequest(`${documentsURL}project/${pid}/`, 'get'),
        axiosRequest(`${threadsURL}project/${pid}/`, 'get'),
      ]);
      setProjectDocuments(docsResp.data);
      setProjectThreads(threadsResp.data);
    } catch (err) {
      console.error('Error fetching project library data:', err);
    }
  };

  // ----------------------------------------------------
  // Project Support: Fetch all documents
  // ----------------------------------------------------
  useEffect(() => {
    const fetchPsDocuments = async () => {
      try {
        const response = await axiosRequest(documentsURL, 'get');
        const sortedDocuments = response.data.sort(
          (a: Document, b: Document) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
        setPsDocuments(sortedDocuments);
      } catch (err: any) {
        setPsError('Error fetching documents');
      }
    };
    fetchPsDocuments();
  }, [documentsURL]);

  // ----------------------------------------------------
  // Project Support submit handler
  // ----------------------------------------------------
  const handlePsSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPsLoading(true);
    setPsError(null);

    if (psDocumentType === 'Type' || psDocumentType === 'None') {
      setPsErrorKey((prevKey) => prevKey + 1);
      setPsError('Document type required. Please select a document type.');
      setPsLoading(false);
      return;
    }

    if (!psDocumentTopic.trim() && !psSelectedDocumentId) {
      setPsErrorKey((prevKey) => prevKey + 1);
      setPsError('Please input a document topic or select a document.');
      setPsLoading(false);
      return;
    }

    try {
      const defaultProjectId = projectId;
      const data: any = {
        documentType: psDocumentType,
        documentTopic: psDocumentTopic,
        documentId: psSelectedDocumentId,
        projectId: defaultProjectId,
      };

      const res = await axiosRequest(projectSupportURL, 'post', data);
      setPsResponse(res.data.document);
      setPsLoading(false);

      if (defaultProjectId) {
        fetchProjectLibraryData(defaultProjectId);
      }
    } catch (error: any) {
      setPsLoading(false);
      setPsErrorKey((prevKey) => prevKey + 1);

      if (error.response?.status === 401) {
        setPsError('Unauthorized. Please log in.');
      } else {
        setPsError('Error generating document.');
      }
      console.error('Error generating document:', error);
    }
  };

  const handlePsTypeDropdownClick = () => {
    setPsShowTypeDropdown(!psShowTypeDropdown);
  };

  const handlePsTypeSelection = (type: string) => {
    setPsDocumentType(type === 'None' ? 'Type' : type);
    setPsShowTypeDropdown(false);
    psLibraryDropdownButtonRef.current?.focus();
  };

  const handlePsLibraryDropdownClick = () => {
    setPsLibraryDropdownOpen(!psLibraryDropdownOpen);
  };

  const handlePsLibrarySelection = (doc: Document | null) => {
    if (doc) {
      setPsSelectedDocumentId(doc.id);
      setPsSelectedDocumentTitle(doc.documentTitle);
    } else {
      setPsSelectedDocumentId(null);
      setPsSelectedDocumentTitle('Library');
    }
    setPsLibraryDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        psTypeDropdownRef.current &&
        !psTypeDropdownRef.current.contains(event.target as Node)
      ) {
        setPsLibraryDropdownOpen(false);
        setPsShowTypeDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (psError && psErrorRef.current) {
      psErrorRef.current.focus();
    }
  }, [psError]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (psShowTypeDropdown && psTypeDropdownMenuRef.current) {
        const items = psTypeDropdownMenuRef.current.querySelectorAll('div[role="menuitem"]');
        const activeIndex = Array.from(items).findIndex(
          (item) => item === document.activeElement
        );
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            (items[(activeIndex + 1) % items.length] as HTMLElement).focus();
            break;
          case 'ArrowUp':
            event.preventDefault();
            (
              items[(activeIndex - 1 + items.length) % items.length] as HTMLElement
            ).focus();
            break;
          case 'Enter':
            if (activeIndex !== -1) {
              event.preventDefault();
              (items[activeIndex] as HTMLElement).click();
            }
            break;
          case 'Escape':
            setPsShowTypeDropdown(false);
            psTypeDropdownButtonRef.current?.focus();
            break;
        }
      }

      if (psLibraryDropdownOpen && psLibraryDropdownMenuRef.current) {
        const items = psLibraryDropdownMenuRef.current.querySelectorAll('div[role="menuitem"]');
        const activeIndex = Array.from(items).findIndex(
          (item) => item === document.activeElement
        );
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            (items[(activeIndex + 1) % items.length] as HTMLElement).focus();
            break;
          case 'ArrowUp':
            event.preventDefault();
            (
              items[(activeIndex - 1 + items.length) % items.length] as HTMLElement
            ).focus();
            break;
          case 'Enter':
            if (activeIndex !== -1) {
              event.preventDefault();
              (items[activeIndex] as HTMLElement).click();
            }
            break;
          case 'Escape':
            setPsLibraryDropdownOpen(false);
            psLibraryDropdownButtonRef.current?.focus();
            break;
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [psShowTypeDropdown, psLibraryDropdownOpen]);

  // ----------------------------------------------------
  // SSE-based fetchProjectDashboard
  // ----------------------------------------------------
  const fetchProjectDashboard = async () => {
    if (!projectId) return;

    // Start overall loading
    setLoading(true);
    setError(null);

    try {
      // 1) POST => /dashboard/ to get temp_token & project_data
      const response = await axiosRequest(`${projectsURL}${projectId}/dashboard/`, 'post');
      const { temp_token, project_data } = response.data;
      setProjectData(project_data);

      // 2) Reset local arrays & context
      setTechScoutingResults([]);
      setStakeholderResults([]);
      setTechResponse({ results: [] });
      setStakeholderResponse([]);

      // 3) Fetch library data
      await fetchProjectLibraryData(projectId);

      // 4) Close any existing SSE
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }

      // 5) Start SSE
      const eventSourceUrl = `${projectsURL}${projectId}/dashboard/?temp_token=${encodeURIComponent(
        temp_token
      )}`;
      const eventSource = new EventSource(eventSourceUrl);
      eventSourceRef.current = eventSource;

      eventSource.onmessage = (evt: MessageEvent) => {
        try {
          const data = JSON.parse(evt.data);
          console.log('SammyEventData', data);

          // If we have partial results, push them
          if (Array.isArray(data.results)) {
            data.results.forEach((r: any) => {
              // Stakeholder item
              if (r.organization) {
                // Push stakeholder
                const newStakeholder: StakeholderData = {
                  organization: r.organization,
                  name: r.name,
                  title: r.title,
                  email: r.email,
                  source: r.source,
                };
                setStakeholderResults((prev) => [...prev, newStakeholder]);
                setStakeholderResponse((prev) => [...prev, newStakeholder]);
              } else if (r.company) {
                // Tech item
                setTechScoutingResults((prev) => [...prev, r]);
                setTechResponse((prevTech: { results: any[] }) => ({
                  results: [...(prevTech.results || []), r],
                }));
              }
            });
          }
        } catch (parseError) {
          console.error('Error parsing SSE data:', parseError);
        }
      };

      // SSE 'end'
      eventSource.addEventListener('end', () => {
        eventSource.close();
        eventSourceRef.current = null;
        setLoading(false);
      });

      // SSE error
      eventSource.onerror = (evt) => {
        console.error('EventSource error:', evt);
        setError('An error occurred while streaming data.');
        eventSource.close();
        eventSourceRef.current = null;
        setLoading(false);
      };
    } catch (err: any) {
      console.error('Error fetching project dashboard:', err);
      if (err.response?.data?.error) {
        setError(err.response.data.error);
      } else {
        setError('Failed to fetch project dashboard.');
      }
      setLoading(false);
    }
  };

  // ----------------------------------------------------
  // Initial SSE fetch with reloadTrigger
  // ----------------------------------------------------
  useEffect(() => {
    fetchProjectDashboard();
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
        eventSourceRef.current = null;
      }
    };
  }, [projectId, projectsURL, reloadTrigger]);

  // Helper to format date/time
  const formatDateTime = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    const formattedDate = date.toLocaleDateString(undefined, options);
    const formattedTime = date.toLocaleTimeString(undefined, { hour12: false });
    return `${formattedDate} ${formattedTime}`;
  };

  const modeClass = isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light';

  // ----------------------------------------------------
  // Delete Project Handler
  // ----------------------------------------------------
  const handleDeleteProject = async () => {
    if (!projectId) return;

    const confirmDelete = window.confirm(
      'Are you sure you want to delete this project? This action cannot be undone.'
    );
    if (!confirmDelete) return;

    setIsDeleting(true);
    setDeleteError(null);
    setDeleteSuccess(null);

    try {
      if (projectData?.is_default) {
        setDeleteError('Default Project cannot be deleted.');
        setIsDeleting(false);
        return;
      }

      await axiosRequest(`${projectsURL}${projectId}/delete/`, 'delete');
      setDeleteSuccess('Project deleted successfully.');

      if (removeProjectFromList) {
        removeProjectFromList(projectId);
      }
      setIsDeleting(false);

      // Switch to TechScouting or some other fallback
      setSection('TechScouting');
    } catch (err: any) {
      console.error('Error deleting project:', err);
      if (err.response?.status === 401) {
        setDeleteError('Unauthorized. Please log in with a valid email and password.');
      } else {
        setDeleteError('Error deleting project.');
      }
      setIsDeleting(false);
    }
  };

  // Enlargers
  const handleEnlargeTechScouting = () => setSection('TechScouting');
  const handleEnlargeStakeholders = () => setSection('StakeholderIdentification');
  const handleEnlargeProjectSupport = () => setSection('ProjectSupport');
  const handleEnlargeMiniLibrary = () => {
    if (projectId) {
      setSelectedLibraryProjectId(projectId);
      setSection('Documents');
    }
  };

  // ----------------------------------------------------
  // Render
  // ----------------------------------------------------
  if (error) {
    return (
      <div className={`project-dashboard-container ${modeClass}`}>
        <p className={`project-dashboard-error-message ${modeClass}`}>{error}</p>
      </div>
    );
  }

  if (!projectData) {
    return (
      <div className={`project-dashboard-container ${modeClass}`}>
        <Loading />
      </div>
    );
  }

  // KEY CHANGE: We derive if each mini-card is "loading" by checking
  // if the entire SSE is "loading" AND we haven't gotten any items yet.
  const isTechMiniLoading = loading && techScoutingResults.length === 0;
  const isStakeholderMiniLoading = loading && stakeholderResults.length === 0;

  return (
    <div className={`project-dashboard-container ${modeClass}`}>
      <Helmet>
        <title>ProjectDashboard</title>
      </Helmet>

      {/* Inline update messages */}
      {updateError && (
        <p className="project-dashboard-inline-update-error" role="alert">
          {updateError}
        </p>
      )}
      {updateSuccess && (
        <p className="project-dashboard-inline-update-success" role="alert">
          {updateSuccess}
        </p>
      )}

      {/* ------ Inline fields for Project Name, Funding, & Technology ------ */}
      <h1 className={`project-dashboard-heading ${modeClass}`}>
        {editingName ? (
          <span className="project-dashboard-inline-edit-container">
            <input
              type="text"
              value={tempName}
              onChange={(e) => setTempName(e.target.value)}
              className="project-dashboard-inline-input"
              aria-label="Edit project name"
            />
            <button onClick={saveName} className="project-dashboard-inline-save-button">
              Save
            </button>
            <button onClick={cancelName} className="project-dashboard-inline-cancel-button">
              Cancel
            </button>
          </span>
        ) : (
          <span className="project-dashboard-inline-display">
            {projectData.name}
            <button
              onClick={() => setEditingName(true)}
              className="project-dashboard-edit-button"
              aria-label="Edit project name"
            >
              <FontAwesomeIcon icon={faPencil} />
            </button>
          </span>
        )}
      </h1>

      <p className={`project-dashboard-text project-dashboard-funding ${modeClass}`}>
        <strong>Funding:</strong>{' '}
        {editingFunding ? (
          <span className="project-dashboard-inline-edit-container">
            <input
              type="number"
              value={tempFunding}
              onChange={(e) => setTempFunding(e.target.value)}
              className="project-dashboard-inline-input"
              aria-label="Edit project funding"
            />
            <button onClick={saveFunding} className="project-dashboard-inline-save-button">
              Save
            </button>
            <button onClick={cancelFunding} className="project-dashboard-inline-cancel-button">
              Cancel
            </button>
          </span>
        ) : (
          <span className="project-dashboard-inline-display">
            ${projectData.funding}
            <button
              onClick={() => setEditingFunding(true)}
              className="project-dashboard-edit-button"
              aria-label="Edit project funding"
            >
              <FontAwesomeIcon icon={faPencil} />
            </button>
          </span>
        )}
      </p>

      <p className={`project-dashboard-text project-dashboard-technology ${modeClass}`}>
        <strong>Technologies:</strong>{' '}
        {editingTechnology ? (
          <span className="project-dashboard-inline-edit-container">
            <input
              type="text"
              value={tempTechnology}
              onChange={(e) => setTempTechnology(e.target.value)}
              className="project-dashboard-inline-input"
              aria-label="Edit project technologies"
            />
            <button onClick={saveTechnology} className="project-dashboard-inline-save-button">
              Save
            </button>
            <button onClick={cancelTechnology} className="project-dashboard-inline-cancel-button">
              Cancel
            </button>
          </span>
        ) : (
          <span className="project-dashboard-inline-display">
            {projectData.technology}
            <button
              onClick={() => setEditingTechnology(true)}
              className="project-dashboard-edit-button"
              aria-label="Edit project technologies"
            >
              <FontAwesomeIcon icon={faPencil} />
            </button>
          </span>
        )}
      </p>

      {/* ------ Timeline ------ */}
      <div className={`project-dashboard-timeline ${modeClass}`}>
        <ul className="project-dashboard-timeline-list">
          <li className="project-dashboard-timeline-item">
            <span className="project-dashboard-timeline-icon" aria-label="Project Initiated">
              <i className="fas fa-rocket"></i>
            </span>
            <span className="project-dashboard-timeline-content">
              Project Initiated
            </span>
          </li>
          <li className="project-dashboard-timeline-item">
            <span className="project-dashboard-timeline-icon" aria-label="Market Research">
              <i className="fas fa-search"></i>
            </span>
            <span className="project-dashboard-timeline-content">
              Market Research
            </span>
          </li>
          <li className="project-dashboard-timeline-item">
            <span className="project-dashboard-timeline-icon" aria-label="Feasibility Study">
              <i className="fas fa-users"></i>
            </span>
            <span className="project-dashboard-timeline-content">
              Feasibility Study
            </span>
          </li>
          <li className="project-dashboard-timeline-item">
            <span className="project-dashboard-timeline-icon" aria-label="Project Execution">
              <i className="fas fa-clipboard-check"></i>
            </span>
            <span className="project-dashboard-timeline-content">
              Project Execution
            </span>
          </li>
        </ul>
      </div>

      {/* ------ Description & Requirements Cards ------ */}
      <div className="project-dashboard-details-row">
        {/* Description Card */}
        <div className={`project-dashboard-detail-col ${modeClass}`}>
          <h3 className="project-dashboard-detail-heading">Description:</h3>
          {editingDescription ? (
            <div className="project-dashboard-inline-edit-container">
              <textarea
                value={tempDescription}
                onChange={(e) => setTempDescription(e.target.value)}
                className="project-dashboard-inline-textarea"
                aria-label="Edit project description"
              />
              <button
                onClick={saveDescription}
                className="project-dashboard-inline-save-button"
              >
                Save
              </button>
              <button
                onClick={cancelDescription}
                className="project-dashboard-inline-cancel-button"
              >
                Cancel
              </button>
            </div>
          ) : (
            <>
              <p className="project-dashboard-detail-text">
                {projectData.description}
              </p>
              <button
                onClick={() => setEditingDescription(true)}
                className="project-dashboard-card-edit-button"
                aria-label="Edit project description"
              >
                <FontAwesomeIcon icon={faPencil} />
              </button>
            </>
          )}
        </div>

        {/* Requirements Card */}
        <div className={`project-dashboard-detail-col ${modeClass}`}>
          <h3 className="project-dashboard-detail-heading">Requirements:</h3>
          {editingRequirements ? (
            <div className="project-dashboard-inline-edit-container">
              <textarea
                value={tempRequirements}
                onChange={(e) => setTempRequirements(e.target.value)}
                className="project-dashboard-inline-textarea"
                aria-label="Edit project requirements"
              />
              <button
                onClick={saveRequirements}
                className="project-dashboard-inline-save-button"
              >
                Save
              </button>
              <button
                onClick={cancelRequirements}
                className="project-dashboard-inline-cancel-button"
              >
                Cancel
              </button>
            </div>
          ) : (
            <>
              <p className="project-dashboard-detail-text">
                {projectData.requirements}
              </p>
              <button
                onClick={() => setEditingRequirements(true)}
                className="project-dashboard-card-edit-button"
                aria-label="Edit project requirements"
              >
                <FontAwesomeIcon icon={faPencil} />
              </button>
            </>
          )}
        </div>
      </div>

      {/* ------ Tech Scouting & Stakeholders Section ------ */}
      <div className="project-dashboard-info-row">
        {/* Tech Scouting Section */}
        <div
          className={`project-dashboard-info-col ${
            isDarkMode ? 'project-dashboard-dark-card' : 'project-dashboard-light-card'
          } animate__animated animate__fadeIn`}
        >
          <div className="project-dashboard-card-header-row">
            <h2 className={`project-dashboard-subheading ${modeClass}`}>
              Tech Scouting
            </h2>
            <button
              className="project-dashboard-search-button"
              aria-label="Enlarge Tech Scouting"
              onClick={() => setSection('TechScouting')}
            >
              Search
            </button>
          </div>
          {/*
            Key Change:
            "isTechMiniLoading" is derived from:
            loading && techScoutingResults.length === 0
            So we show a spinner ONLY if overall SSE is still "loading" AND we have no items yet.
          */}
          {loading && techScoutingResults.length === 0 ? (
            <Loading />
          ) : (
            <ul
              className={`project-dashboard-results-list project-dashboard-tech-scouting-list ${modeClass}`}
            >
              {techScoutingResults.slice(0, 4).map((res, idx) => (
                <li key={idx} className="project-dashboard-result-item">
                  {res.company || 'Unknown Company'}
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Stakeholders Section */}
        <div
          className={`project-dashboard-info-col ${
            isDarkMode ? 'project-dashboard-dark-card' : 'project-dashboard-light-card'
          } animate__animated animate__fadeIn`}
        >
          <div className="project-dashboard-card-header-row">
            <h2
              className={`project-dashboard-subheading ${modeClass} stakeholders-subheading`}
            >
              Stakeholders
            </h2>
            <button
              className="project-dashboard-search-button"
              aria-label="Enlarge Stakeholders"
              onClick={() => setSection('StakeholderIdentification')}
            >
              Search
            </button>
          </div>
          {/*
            Similarly for stakeholders:
            Show spinner if "loading && stakeholderResults.length===0"
          */}
          {loading && stakeholderResults.length === 0 ? (
            <Loading />
          ) : (
            <ul
              className={`project-dashboard-results-list project-dashboard-stakeholders-list ${modeClass}`}
            >
              {stakeholderResults.slice(0, 4).map((res, idx) => (
                <li key={idx} className="project-dashboard-result-item">
                  {res.organization || 'Unknown Organization'}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {/* ------ Project Support & Mini Library Section ------ */}
      <div className="project-dashboard-info-row">
        {/* Project Support */}
        <div
          className={`project-dashboard-info-col project-dashboard-project-support-col ${
            isDarkMode ? 'project-dashboard-dark-card' : 'project-dashboard-light-card'
          } animate__animated animate__fadeIn`}
        >
          <div className="project-dashboard-card-header-row">
            <h2 className={`project-dashboard-subheading ${modeClass}`}>
              Project Support
            </h2>
            <button
              className="project-dashboard-enlarge-button"
              aria-label="Enlarge Project Support"
              onClick={() => setSection('ProjectSupport')}
            >
              ⛶
            </button>
          </div>
          <form
            className="project-dashboard-project-support-form"
            onSubmit={handlePsSubmit}
          >
            <div
              className="project-dashboard-project-support-input-container"
              ref={psTypeDropdownRef}
            >
              <label
                htmlFor="project-dashboard-project-support-query"
                className="project-dashboard-visually-hidden"
              >
                Document Topic
              </label>
              <input
                id="project-dashboard-project-support-query"
                type="text"
                value={psDocumentTopic}
                onChange={(e) => setPsDocumentTopic(e.target.value)}
                placeholder="Ex: Write a use case..."
                className={`project-dashboard-project-support-input ${
                  isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
                }`}
              />
              <div className="project-dashboard-project-support-buttons-container">
                <div className="project-dashboard-project-support-button-with-tooltip">
                  <button
                    type="button"
                    className={`project-dashboard-project-support-dropdown-button ${
                      isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
                    }`}
                    onClick={handlePsTypeDropdownClick}
                    aria-expanded={psShowTypeDropdown}
                    aria-controls="project-dashboard-project-support-dropdown-menu"
                    ref={psTypeDropdownButtonRef}
                  >
                    &#9776; {psDocumentType}
                  </button>
                  <span className="project-dashboard-project-support-tooltip-text">
                    The type of document to be generated
                  </span>
                </div>
                <div className="project-dashboard-project-support-button-with-tooltip">
                  <button
                    type="button"
                    className={`project-dashboard-project-support-library-dropdown-button ${
                      isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
                    }`}
                    onClick={handlePsLibraryDropdownClick}
                    aria-expanded={psLibraryDropdownOpen}
                    aria-controls="project-dashboard-project-support-library-dropdown-menu"
                    ref={psLibraryDropdownButtonRef}
                  >
                    &#128194; {psSelectedDocumentTitle}
                  </button>
                  <span className="project-dashboard-project-support-tooltip-text">
                    Select a document from your library
                  </span>
                </div>
                <button
                  type="submit"
                  className={`project-dashboard-project-support-submit-button ${
                    isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
                  }`}
                  aria-label="Submit Button"
                  ref={psSubmitButtonRef}
                >
                  &#x27A4;
                </button>
              </div>

              {/* Type Dropdown */}
              <div
                className={`project-dashboard-project-support-dropdown-menu ${
                  isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
                } ${psShowTypeDropdown ? 'open' : 'closed'}`}
                id="project-dashboard-project-support-dropdown-menu"
                role="menu"
                ref={psTypeDropdownMenuRef}
                aria-hidden={!psShowTypeDropdown}
              >
                {psDocumentTypes.map((type) => (
                  <div
                    key={type}
                    tabIndex={0}
                    role="menuitem"
                    className={`project-dashboard-project-support-dropdown-item ${
                      isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
                    }`}
                    onClick={() => handlePsTypeSelection(type)}
                  >
                    {type}
                  </div>
                ))}
              </div>

              {/* Library Dropdown */}
              <div
                className={`project-dashboard-project-support-library-dropdown-menu ${
                  isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
                } ${psLibraryDropdownOpen ? 'open' : 'closed'}`}
                id="project-dashboard-project-support-library-dropdown-menu"
                role="menu"
                ref={psLibraryDropdownMenuRef}
                aria-hidden={!psLibraryDropdownOpen}
              >
                <div
                  role="menuitem"
                  tabIndex={0}
                  onClick={() => handlePsLibrarySelection(null)}
                  className={`project-dashboard-project-support-dropdown-item ${
                    isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
                  }`}
                >
                  -- No Document --
                </div>
                {psDocuments.map((doc) => (
                  <div
                    key={doc.id}
                    role="menuitem"
                    tabIndex={0}
                    onClick={() => handlePsLibrarySelection(doc)}
                    className={`project-dashboard-project-support-dropdown-item ${
                      isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
                    }`}
                  >
                    {doc.documentTitle}
                  </div>
                ))}
              </div>
            </div>
            <div
              id="project-dashboard-project-support-error-message"
              key={psErrorKey}
              className={`project-dashboard-project-support-error ${
                psError ? 'visible' : 'hidden'
              } ${isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'}`}
              aria-live="assertive"
              role="alert"
              ref={psErrorRef}
              tabIndex={-1}
            >
              {psError}
            </div>
          </form>

          {psLoading && <Loading />}
          {psResponse && (
            <div
              className={`project-dashboard-project-support-response ${
                isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
              }`}
            >
              <h2>Document Generated!</h2>
              <a
                href={`data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${psResponse}`}
                download="Generated_Document.docx"
                className={`project-dashboard-project-support-download-button ${
                  isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
                }`}
              >
                Download Document
              </a>
            </div>
          )}
        </div>

        {/* Mini Library Section */}
        <div
          className={`project-dashboard-info-col project-dashboard-mini-library-col ${
            isDarkMode ? 'project-dashboard-dark-card' : 'project-dashboard-light-card'
          } animate__animated animate__fadeIn`}
        >
          <div className={`project-dashboard-mini-library-container ${modeClass}`}>
            <div className="project-dashboard-card-header-row">
              <div className="project-dashboard-mini-library-left-group">
                <h2 className={`project-dashboard-mini-library-title ${modeClass}`}>
                  Project Library
                </h2>
                <div className="project-dashboard-mini-library-toggle-container">
                  <button
                    className={`project-dashboard-mini-library-toggle-button ${
                      miniActiveTab === 'documents' ? 'active' : ''
                    } ${modeClass}`}
                    onClick={() => setMiniActiveTab('documents')}
                  >
                    Documents
                  </button>
                  <span className="project-dashboard-mini-library-toggle-separator">
                    /
                  </span>
                  <button
                    className={`project-dashboard-mini-library-toggle-button ${
                      miniActiveTab === 'threads' ? 'active' : ''
                    } ${modeClass}`}
                    onClick={() => setMiniActiveTab('threads')}
                  >
                    Threads
                  </button>
                </div>
              </div>
              <button
                className="project-dashboard-enlarge-button"
                aria-label="Enlarge Project Library"
                onClick={() => {
                  if (projectId) {
                    setSelectedLibraryProjectId(projectId);
                    setSection('Documents');
                  }
                }}
              >
                ⛶
              </button>
            </div>
            <table className="project-dashboard-mini-library-table">
              <thead>
                <tr>
                  {miniActiveTab === 'documents' ? (
                    <>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Created At</th>
                    </>
                  ) : (
                    <>
                      <th>Prompt</th>
                      <th>Type</th>
                      <th>Created At</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {miniActiveTab === 'documents' ? (
                  projectDocuments.length > 0 ? (
                    projectDocuments.map((doc) => (
                      <tr key={doc.id}>
                        <td title={doc.documentTitle}>{doc.documentTitle}</td>
                        <td>{doc.documentType}</td>
                        <td>{formatDateTime(doc.created_at)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>No documents found.</td>
                    </tr>
                  )
                ) : projectThreads.length > 0 ? (
                  projectThreads.map((thread) => (
                    <tr key={thread.id}>
                      <td title={thread.threadPrompt}>{thread.threadPrompt}</td>
                      <td>{thread.threadType}</td>
                      <td>{formatDateTime(thread.created_at)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={3}>No threads found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* ------ Metadata ------ */}
      <div className={`project-dashboard-metadata ${modeClass} animate__animated animate__fadeIn`}>
        <p className={`project-dashboard-text ${modeClass}`}>
          <strong>Created At:</strong>{' '}
          {new Date(projectData.created_at).toLocaleString()}
        </p>
        <p className={`project-dashboard-text ${modeClass}`}>
          <strong>Last Updated:</strong>{' '}
          {new Date(projectData.updated_at).toLocaleString()}
        </p>
        <p className={`project-dashboard-text ${modeClass}`}>
          <strong>Default Project:</strong>{' '}
          {projectData.is_default ? 'Yes' : 'No'}
        </p>
      </div>

      {/* ------ Delete Project Section ------ */}
      <div className={`project-dashboard-delete-section ${modeClass} animate__animated animate__fadeIn`}>
        <button
          className="project-dashboard-delete-button"
          onClick={handleDeleteProject}
          disabled={isDeleting}
          aria-label="Delete Project"
        >
          {isDeleting ? 'Deleting...' : 'Delete Project'}
        </button>
        {deleteError && (
          <p className="project-dashboard-delete-error" role="alert">
            {deleteError}
          </p>
        )}
        {deleteSuccess && (
          <p className="project-dashboard-delete-success" role="alert">
            {deleteSuccess}
          </p>
        )}
      </div>
    </div>
  );
};

export default ProjectDashboard;
