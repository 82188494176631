// src/components/ProjectDetail.tsx

import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import axiosRequest from '../utils/axiosRequest';
import './style/ProjectDetail.css'; // Import the CSS file
import { Project, Document, Thread } from '../types'; // Ensure these types are defined appropriately
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPencil,
    faArrowLeft,
    faChevronDown,
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import LibraryModal from './LibraryModal'; // Reuse the LibraryModal component
import { TechScoutingContext, StakeholderIdentificationContext } from '../ContextStore';

interface AddDocumentFormProps {
    projectId: string;
    documentsURL: string;
    onSuccess: () => void;
}

const AddDocumentForm: React.FC<AddDocumentFormProps> = ({
    projectId,
    documentsURL,
    onSuccess,
}) => {
    const [file, setFile] = useState<File | null>(null);
    const [documentType, setDocumentType] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!file || !documentType.trim()) {
            setError('Please select a file and enter a document type.');
            return;
        }

        const formData = new FormData();
        formData.append('document', file);
        formData.append('documentType', documentType);
        formData.append('project_id', projectId);

        try {
            await axiosRequest(`${documentsURL}upload/`, 'post', formData);
            setSuccessMessage('Document added to project successfully.');
            setFile(null);
            setDocumentType('');
            onSuccess();
        } catch (err: any) {
            if (err.response && err.response.status === 401) {
                setError('Unauthorized. Please log in with a valid email and password.');
            } else {
                setError('Error adding document to project.');
            }
        }
    };

    return (
        <form onSubmit={handleSubmit} className="project-detail-add-document-form">
            {error && (
                <p className="project-detail-error-message" role="alert">
                    {error}
                </p>
            )}
            {successMessage && (
                <p className="project-detail-success-message" role="alert">
                    {successMessage}
                </p>
            )}
            <label htmlFor="document" className="project-detail-form-label">
                Select Document:
            </label>
            <input
                type="file"
                id="document"
                onChange={handleFileChange}
                required
                className="project-detail-form-input-file"
            />
            <label htmlFor="documentType" className="project-detail-form-label">
                Document Type:
            </label>
            <input
                type="text"
                id="documentType"
                value={documentType}
                onChange={(e) => setDocumentType(e.target.value)}
                placeholder="Enter document type"
                required
                className="project-detail-form-input-text"
            />
            <button type="submit" className="project-detail-form-button">
                Add Document
            </button>
        </form>
    );
};

interface ProjectDetailProps {
    projectId: string;
    isDarkMode: boolean;
    setSection: React.Dispatch<React.SetStateAction<string>>;
    setSelectedProjectId: React.Dispatch<React.SetStateAction<string | null>>;
}

const ProjectDetail: React.FC<ProjectDetailProps> = ({
    projectId,
    isDarkMode,
    setSection,
    setSelectedProjectId,
}) => {
    // Import API URLs from environment variables
    const documentsURL = process.env.REACT_APP_DOCUMENTS_API_URL;
    const threadsURL = process.env.REACT_APP_THREADS_API_URL;
    const projectsURL = process.env.REACT_APP_PROJECTS_API_URL;

    // Validate that all URLs are defined
    if (!documentsURL || !threadsURL || !projectsURL) {
        throw new Error('API URLs are not defined');
    }

    // Access TechScoutingContext
    const techScoutingContext = useContext(TechScoutingContext);
    if (!techScoutingContext) {
        throw new Error('TechScoutingContext not found');
    }
    const { setTechResponse } = techScoutingContext;

    // Access StakeholderIdentificationContext
    const stakeholderContext = useContext(StakeholderIdentificationContext);
    if (!stakeholderContext) {
        throw new Error('StakeholderIdentificationContext not found');
    }
    const { setStakeholderResponse } = stakeholderContext;

    // State Management
    const [project, setProject] = useState<Project | null>(null);
    const [documents, setDocuments] = useState<Document[]>([]);
    const [projectThreads, setProjectThreads] = useState<Thread[]>([]);
    const [allThreads, setAllThreads] = useState<Thread[]>([]);
    const [allDocuments, setAllDocuments] = useState<Document[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    // States for renaming project
    const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
    const [editedTitle, setEditedTitle] = useState<string>('');
    const [isEditingDescription, setIsEditingDescription] = useState<boolean>(false);
    const [editedDescription, setEditedDescription] = useState<string>('');

    // ---------- NEW: States & logic for renaming Documents and Threads ----------
    // Documents rename states
    const [editingDocumentId, setEditingDocumentId] = useState<string | null>(null);
    const [newDocumentTitle, setNewDocumentTitle] = useState<string>('');
    const [docError, setDocError] = useState<string | null>(null);
    const [docSuccessMessage, setDocSuccessMessage] = useState<string | null>(null);

    // Threads rename states
    const [editingThreadId, setEditingThreadId] = useState<string | null>(null);
    const [newThreadPrompt, setNewThreadPrompt] = useState<string>('');
    const [threadError, setThreadError] = useState<string | null>(null);
    const [threadSuccessMessage, setThreadSuccessMessage] = useState<string | null>(null);

    // States for modals
    const [showAddDocumentModal, setShowAddDocumentModal] = useState<boolean>(false);

    // States for adding existing thread and document
    const [showThreadDropdown, setShowThreadDropdown] = useState<boolean>(false);
    const [showDocDropdown, setShowDocDropdown] = useState<boolean>(false);

    // Refs for input focus and dropdowns
    const titleInputRef = useRef<HTMLInputElement | null>(null);
    const descriptionInputRef = useRef<HTMLTextAreaElement | null>(null);
    const threadDropdownRef = useRef<HTMLDivElement | null>(null);
    const docDropdownRef = useRef<HTMLDivElement | null>(null);

    // ------------------ Fetch Functions ------------------ //
    const fetchProjectDetails = useCallback(async () => {
        try {
            const response = await axiosRequest(`${projectsURL}${projectId}/`, 'get');
            setProject(response.data);
        } catch (err: any) {
            setError('Error fetching project details.');
        }
    }, [projectsURL, projectId]);

    const fetchProjectDocuments = useCallback(async () => {
        try {
            const response = await axiosRequest(`${documentsURL}project/${projectId}/`, 'get');
            // Sort documents by created_at descending
            const sortedDocuments = response.data.sort((a: Document, b: Document) => {
                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            });
            setDocuments(sortedDocuments);
        } catch (err: any) {
            setError('Error fetching project documents.');
        }
    }, [documentsURL, projectId]);

    const fetchProjectThreads = useCallback(async () => {
        try {
            const response = await axiosRequest(`${threadsURL}project/${projectId}/`, 'get');
            // Sort threads by created_at descending
            const sortedThreads = response.data.sort((a: Thread, b: Thread) => {
                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            });
            setProjectThreads(sortedThreads);
        } catch (err: any) {
            setError('Error fetching project threads.');
        }
    }, [threadsURL, projectId]);

    const fetchAllThreads = useCallback(async () => {
        try {
            const response = await axiosRequest(`${threadsURL}`, 'get');
            setAllThreads(response.data);
        } catch (err: any) {
            setError('Error fetching all threads.');
        }
    }, [threadsURL]);

    const fetchAllDocuments = useCallback(async () => {
        try {
            const response = await axiosRequest(`${documentsURL}`, 'get');
            setAllDocuments(response.data);
        } catch (err: any) {
            setError('Error fetching all documents.');
        }
    }, [documentsURL]);

    // ------------- Initial Data Fetching ------------- //
    useEffect(() => {
        fetchProjectDetails();
        fetchProjectDocuments();
        fetchProjectThreads();
        fetchAllThreads();
        fetchAllDocuments();
    }, [
        fetchProjectDetails,
        fetchProjectDocuments,
        fetchProjectThreads,
        fetchAllThreads,
        fetchAllDocuments,
    ]);

    // Sync editedTitle and editedDescription with project data
    useEffect(() => {
        if (project) {
            setEditedTitle(project.name || '');
            setEditedDescription(project.description || '');
        }
    }, [project]);

    // ------------------ Update Project Title/Description ------------------ //
    const handleUpdateProject = async () => {
        if (!editedTitle.trim() && !editedDescription.trim()) {
            setError('Project title and description cannot both be empty.');
            return;
        }

        try {
            const payload: Partial<Pick<Project, 'name' | 'description'>> = {};
            if (editedTitle.trim()) {
                payload.name = editedTitle;
            }
            if (editedDescription.trim()) {
                payload.description = editedDescription;
            }

            const response = await axiosRequest(
                `${projectsURL}${projectId}/update/`,
                'patch',
                payload
            );

            // Assuming the backend returns { message: string, project: Project }
            setProject(response.data.project);
            setSuccessMessage(response.data.message);
            setIsEditingTitle(false);
            setIsEditingDescription(false);
        } catch (err: any) {
            if (err.response && err.response.data && err.response.data.error) {
                setError(err.response.data.error);
            } else {
                setError('Error updating project.');
            }
        }
    };

    // ------------------ Rename Document Handler ------------------ //
    const handleRenameDocument = async (documentId: string) => {
        if (!newDocumentTitle.trim()) {
            setDocError('Document title cannot be empty.');
            return;
        }
        try {
            const response = await axiosRequest(
                `${documentsURL}${documentId}/rename/`,
                'patch',
                { documentTitle: newDocumentTitle }
            );

            // Update the document title in the documents list
            setDocuments((prevDocs) =>
                prevDocs.map((doc) =>
                    doc.id === documentId
                        ? { ...doc, documentTitle: response.data.documentTitle }
                        : doc
                )
            );

            setDocSuccessMessage('Document renamed successfully.');
            setEditingDocumentId(null);
            setNewDocumentTitle('');
        } catch (err: any) {
            if (err.response && err.response.status === 401) {
                setDocError('Unauthorized. Please log in with a valid email and password.');
            } else {
                setDocError('Error renaming document.');
            }
        }
    };

    // ------------------ Rename Thread Handler ------------------ //
    const handleRenameThread = async (threadId: string) => {
        if (!newThreadPrompt.trim()) {
            setThreadError('Thread prompt cannot be empty.');
            return;
        }
        try {
            const response = await axiosRequest(
                `${threadsURL}${threadId}/rename/`,
                'patch',
                { threadPrompt: newThreadPrompt }
            );

            // Update the thread prompt in the projectThreads list
            setProjectThreads((prevThreads) =>
                prevThreads.map((thread) =>
                    thread.id === threadId
                        ? { ...thread, threadPrompt: response.data.threadPrompt }
                        : thread
                )
            );

            setThreadSuccessMessage('Thread renamed successfully.');
            setEditingThreadId(null);
            setNewThreadPrompt('');
        } catch (err: any) {
            if (err.response && err.response.status === 401) {
                setThreadError('Unauthorized. Please log in with a valid email and password.');
            } else {
                setThreadError('Error renaming thread.');
            }
        }
    };

    // ------------------ Format date/time helper ------------------ //
    const formatDateTime = (dateString: string) => {
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        };
        const formattedDate = date.toLocaleDateString(undefined, options);
        const formattedTime = date.toLocaleTimeString(undefined, { hour12: false });
        return `${formattedDate} ${formattedTime}`;
    };

    // ------------------ Document Handlers ------------------ //
    const handleDownload = async (documentId: string, documentTitle: string) => {
        try {
            const response = await axiosRequest(`${documentsURL}${documentId}/download/`, 'get');
            const url = response.data.url;
            const link = document.createElement('a');
            link.href = url;
            link.download = documentTitle;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (err: any) {
            alert('Error downloading document.');
        }
    };

    const handleDeleteDocument = async (documentId: string) => {
        if (!window.confirm('Are you sure you want to delete this document?')) return;
        try {
            await axiosRequest(`${documentsURL}${documentId}/delete/`, 'delete');
            alert('Document deleted successfully.');
            setDocuments((prevDocs) => prevDocs.filter((doc) => doc.id !== documentId));
            await fetchAllDocuments();
        } catch (err: any) {
            alert('Error deleting document.');
        }
    };

    const handleRemoveDocument = async (documentId: string) => {
        if (!window.confirm('Are you sure you want to remove this document from the project?')) {
            return;
        }
        try {
            await axiosRequest(`${documentsURL}remove-from-project/`, 'post', {
                project_id: projectId,
                document_id: documentId,
            });
            alert('Document removed from project successfully.');
            setDocuments((prevDocs) => prevDocs.filter((doc) => doc.id !== documentId));
            await fetchAllDocuments();
        } catch (err: any) {
            if (err.response && err.response.status === 401) {
                alert('Unauthorized. Please log in with a valid email and password.');
            } else {
                alert('Error removing document from project.');
            }
        }
    };

    // ------------------ Thread Handlers ------------------ //
    const handleViewThread = (threadId: string) => {
        const thread = projectThreads.find((t) => t.id === threadId);
        if (!thread) {
            alert('Thread not found.');
            return;
        }

        let responseData;
        try {
            responseData = JSON.parse(thread.threadText);
        } catch (err) {
            console.error('Error parsing threadText:', err);
            alert('Error parsing thread data.');
            return;
        }

        if (thread.threadType === 'TechScouting') {
            setTechResponse(responseData);
            setSection('TechScouting');
        } else if (thread.threadType === 'StakeholderIdentification') {
            setStakeholderResponse(responseData);
            setSection('StakeholderIdentification');
        } else {
            alert('Unsupported thread type.');
        }
    };

    const handleDeleteThread = async (threadId: string) => {
        if (!window.confirm('Are you sure you want to delete this thread?')) return;
        try {
            await axiosRequest(`${threadsURL}${threadId}/delete/`, 'delete');
            alert('Thread deleted successfully.');
            setProjectThreads((prevThreads) => prevThreads.filter((t) => t.id !== threadId));
            await fetchAllThreads();
        } catch (err: any) {
            alert('Error deleting thread.');
        }
    };

    const handleRemoveThread = async (threadId: string) => {
        if (!window.confirm('Are you sure you want to remove this thread from the project?')) return;
        try {
            await axiosRequest(`${threadsURL}remove-from-project/`, 'post', {
                thread_id: threadId,
                project_id: projectId,
            });
            alert('Thread removed from project successfully.');
            setProjectThreads((prevThreads) => prevThreads.filter((t) => t.id !== threadId));
            await fetchAllThreads();
        } catch (err: any) {
            alert('Error removing thread from project.');
        }
    };

    // ------------------ Add Existing Items ------------------ //
    const handleAddExistingThread = async (threadId: string) => {
        try {
            await axiosRequest(`${threadsURL}add-to-project/`, 'post', {
                thread_id: threadId,
                project_id: projectId,
            });
            alert('Thread added to project successfully.');
            const addedThread = allThreads.find((t) => t.id === threadId);
            if (addedThread) {
                setProjectThreads((prevThreads) => [...prevThreads, addedThread]);
            }
            setShowThreadDropdown(false);
        } catch (err: any) {
            if (err.response && err.response.status === 401) {
                alert('Unauthorized. Please log in with a valid email and password.');
            } else {
                alert('Error adding thread to project.');
            }
        }
    };

    const handleAddExistingDocument = async (documentId: string) => {
        try {
            await axiosRequest(`${documentsURL}add-to-project/`, 'post', {
                document_id: documentId,
                project_id: projectId,
            });
            alert('Document added to project successfully.');
            const addedDocument = allDocuments.find((doc) => doc.id === documentId);
            if (addedDocument) {
                setDocuments((prevDocs) => [...prevDocs, addedDocument]);
            }
            setShowDocDropdown(false);
        } catch (err: any) {
            if (err.response && err.response.status === 401) {
                alert('Unauthorized. Please log in with a valid email and password.');
            } else {
                alert('Error adding document to project.');
            }
        }
    };

    // ------------------ Close Dropdowns on Outside Click ------------------ //
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                threadDropdownRef.current &&
                !threadDropdownRef.current.contains(event.target as Node)
            ) {
                setShowThreadDropdown(false);
            }
            if (
                docDropdownRef.current &&
                !docDropdownRef.current.contains(event.target as Node)
            ) {
                setShowDocDropdown(false);
            }
        };
        if (showThreadDropdown || showDocDropdown) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showThreadDropdown, showDocDropdown]);

    // ------------------ Auto-clear messages after 5s (Optional) ------------------ //
    useEffect(() => {
        if (error || successMessage) {
            const timer = setTimeout(() => {
                setError(null);
                setSuccessMessage(null);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [error, successMessage]);

    // Also clear rename-specific messages
    useEffect(() => {
        if (docError || docSuccessMessage) {
            const timer = setTimeout(() => {
                setDocError(null);
                setDocSuccessMessage(null);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [docError, docSuccessMessage]);

    useEffect(() => {
        if (threadError || threadSuccessMessage) {
            const timer = setTimeout(() => {
                setThreadError(null);
                setThreadSuccessMessage(null);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [threadError, threadSuccessMessage]);

    // ------------- Filtering available items ------------- //
    const availableDocuments = allDocuments.filter(
        (doc) => !documents.some((pDoc) => pDoc.id === doc.id)
    );
    const availableThreads = allThreads.filter(
        (t) => !projectThreads.some((pThread) => pThread.id === t.id)
    );

    // ------------- Focus logic for editing project info ------------- //
    useEffect(() => {
        if (isEditingTitle && titleInputRef.current) {
            titleInputRef.current.focus();
        }
    }, [isEditingTitle]);

    useEffect(() => {
        if (isEditingDescription && descriptionInputRef.current) {
            descriptionInputRef.current.focus();
        }
    }, [isEditingDescription]);

    // ------------- Navigation ------------- //
    const handleGoBack = () => {
        setSelectedProjectId(projectId);
        setSection('ProjectDashboard');
    };
    

    // ------------- Conditional rendering if project is not loaded ------------- //
    if (!project) {
        return (
            <div
                className={`project-detail-container ${
                    isDarkMode ? 'project-detail-dark' : 'project-detail-light'
                }`}
            >
                {error ? (
                    <p className="project-detail-error-message" role="alert">
                        {error}
                    </p>
                ) : (
                    <p>Loading project details...</p>
                )}
                <button
                    onClick={handleGoBack}
                    className={`project-detail-back-button ${
                        isDarkMode
                            ? 'project-detail-back-button-dark'
                            : 'project-detail-back-button-light'
                    }`}
                    aria-label="Go Back to Library"
                >
                    <FontAwesomeIcon icon={faArrowLeft} /> Go Back
                </button>
            </div>
        );
    }

    // At this point, TypeScript knows that 'project' is not null
    const { name = '', description = '' } = project;

    return (
        <div
            className={`project-detail-container ${
                isDarkMode ? 'project-detail-dark' : 'project-detail-light'
            }`}
        >
            <nav aria-label="Primary Navigation" className="primary-nav"></nav>
            <main role="main" tabIndex={-1} className="main-content">
                {/* Project Header */}
                <div className="project-detail-project-header">
                    {/* Project Title and Description Section */}
                    <div className="project-detail-title-section">
                        {isEditingTitle || isEditingDescription ? (
                            <div className="project-detail-inline-edit">
                                {isEditingTitle && (
                                    <input
                                        type="text"
                                        value={editedTitle}
                                        onChange={(e) => setEditedTitle(e.target.value)}
                                        className={`project-detail-inline-input ${
                                            isDarkMode
                                                ? 'project-detail-dark'
                                                : 'project-detail-light'
                                        }`}
                                        aria-label="Edit Project Title"
                                        ref={titleInputRef}
                                    />
                                )}
                                {isEditingDescription && (
                                    <textarea
                                        value={editedDescription}
                                        onChange={(e) => setEditedDescription(e.target.value)}
                                        className={`project-detail-inline-textarea ${
                                            isDarkMode
                                                ? 'project-detail-dark'
                                                : 'project-detail-light'
                                        }`}
                                        aria-label="Edit Project Description"
                                        ref={descriptionInputRef}
                                    />
                                )}
                                <button
                                    onClick={handleUpdateProject}
                                    className="project-detail-inline-save-button"
                                    aria-label="Save Project Changes"
                                >
                                    Save
                                </button>
                                <button
                                    onClick={() => {
                                        if (isEditingTitle) {
                                            setIsEditingTitle(false);
                                            setEditedTitle(name);
                                        }
                                        if (isEditingDescription) {
                                            setIsEditingDescription(false);
                                            setEditedDescription(description);
                                        }
                                    }}
                                    className="project-detail-inline-cancel-button"
                                    aria-label="Cancel Editing Project"
                                >
                                    Cancel
                                </button>
                            </div>
                        ) : (
                            <div className="project-detail-display-field">
                                <h1
                                    className={`project-detail-title ${
                                        isDarkMode
                                            ? 'project-detail-title-dark'
                                            : 'project-detail-title-light'
                                    }`}
                                >
                                    {name}
                                </h1>
                                <button
                                    onClick={() => {
                                        setIsEditingTitle(true);
                                        setEditedTitle(name);
                                    }}
                                    className="project-detail-edit-button"
                                    aria-label="Edit Project Title"
                                >
                                    <FontAwesomeIcon icon={faPencil} />
                                </button>
                            </div>
                        )}
                    </div>

                    {/* Project Description Section */}
                    <div className="project-detail-description-section">
                        {!isEditingTitle && !isEditingDescription && (
                            <div className="project-detail-display-field">
                                <p className="project-detail-description">{description}</p>
                                <button
                                    onClick={() => {
                                        setIsEditingDescription(true);
                                        setEditedDescription(description);
                                    }}
                                    className="project-detail-edit-button"
                                    aria-label="Edit Project Description"
                                >
                                    <FontAwesomeIcon icon={faPencil} />
                                </button>
                            </div>
                        )}
                    </div>
                </div>

                {/* Top-level Errors or Success Messages */}
                {error && (
                    <p className="project-detail-error-message" role="alert">
                        {error}
                    </p>
                )}
                {successMessage && (
                    <p className="project-detail-success-message" role="alert">
                        {successMessage}
                    </p>
                )}
                {/* Rename Document Messages */}
                {docError && (
                    <p className="project-detail-error-message" role="alert">
                        {docError}
                    </p>
                )}
                {docSuccessMessage && (
                    <p className="project-detail-success-message" role="alert">
                        {docSuccessMessage}
                    </p>
                )}
                {/* Rename Thread Messages */}
                {threadError && (
                    <p className="project-detail-error-message" role="alert">
                        {threadError}
                    </p>
                )}
                {threadSuccessMessage && (
                    <p className="project-detail-success-message" role="alert">
                        {threadSuccessMessage}
                    </p>
                )}

                {/* Documents Section */}
                <div className="project-detail-section">
                    <div className="project-detail-section-header">
                        <h2 className="project-detail-section-title">Documents</h2>
                        <div className="project-detail-doc-buttons">
                            {/* Upload Document Button */}
                            <button
                                onClick={() => setShowAddDocumentModal(true)}
                                className="project-detail-add-button"
                                aria-label="Upload Document"
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </button>

                            {/* Add Existing Document Dropdown Button */}
                            <div className="project-detail-doc-add-container" ref={docDropdownRef}>
                                <button
                                    onClick={() => {
                                        fetchAllDocuments();
                                        setShowDocDropdown((prev) => !prev);
                                    }}
                                    className="project-detail-add-existing-button"
                                    aria-label="Add Existing Document"
                                    aria-haspopup="true"
                                    aria-expanded={showDocDropdown}
                                >
                                    <FontAwesomeIcon icon={faChevronDown} />
                                </button>
                                {showDocDropdown && (
                                    <ul className="project-detail-doc-dropdown">
                                        {availableDocuments.length === 0 ? (
                                            <li className="project-detail-dropdown-item">
                                                No available documents.
                                            </li>
                                        ) : (
                                            availableDocuments.map((doc) => (
                                                <li
                                                    key={doc.id}
                                                    className="project-detail-dropdown-item"
                                                    tabIndex={0}
                                                    onClick={() => handleAddExistingDocument(doc.id)}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            handleAddExistingDocument(doc.id);
                                                        }
                                                    }}
                                                >
                                                    {doc.documentTitle}
                                                </li>
                                            ))
                                        )}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                    {documents.length === 0 ? (
                        <p>No documents found for this project.</p>
                    ) : (
                        <table
                            className="project-detail-table"
                            role="table"
                            aria-label="Documents table"
                        >
                            <thead>
                                <tr>
                                    <th
                                        className={`project-detail-table-header ${
                                            isDarkMode
                                                ? 'project-detail-table-header-dark'
                                                : 'project-detail-table-header-light'
                                        }`}
                                    >
                                        Name
                                    </th>
                                    <th
                                        className={`project-detail-table-header ${
                                            isDarkMode
                                                ? 'project-detail-table-header-dark'
                                                : 'project-detail-table-header-light'
                                        }`}
                                    >
                                        Type
                                    </th>
                                    <th
                                        className={`project-detail-table-header ${
                                            isDarkMode
                                                ? 'project-detail-table-header-dark'
                                                : 'project-detail-table-header-light'
                                        }`}
                                    >
                                        Created At
                                    </th>
                                    <th
                                        className={`project-detail-table-header ${
                                            isDarkMode
                                                ? 'project-detail-table-header-dark'
                                                : 'project-detail-table-header-light'
                                        }`}
                                    >
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {documents.map((doc) => (
                                    <tr key={doc.id} className="project-detail-table-row">
                                        <td className="project-detail-table-cell">
                                            {editingDocumentId === doc.id ? (
                                                <div className="project-detail-rename-container">
                                                    <input
                                                        type="text"
                                                        value={newDocumentTitle}
                                                        onChange={(e) => setNewDocumentTitle(e.target.value)}
                                                        className="rename-input"
                                                        aria-label={`Rename document ${doc.documentTitle}`}
                                                    />
                                                    <button
                                                        onClick={() => handleRenameDocument(doc.id)}
                                                        className="rename-save-button"
                                                    >
                                                        Save
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            setEditingDocumentId(null);
                                                            setNewDocumentTitle('');
                                                        }}
                                                        className="rename-cancel-button"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            ) : (
                                                <div className="project-detail-rename-container">
                                                    {doc.documentTitle}
                                                    <button
                                                        onClick={() => {
                                                            setEditingDocumentId(doc.id);
                                                            setNewDocumentTitle(doc.documentTitle);
                                                        }}
                                                        className="project-detail-edit-button"
                                                        aria-label={`Rename document ${doc.documentTitle}`}
                                                    >
                                                        <FontAwesomeIcon icon={faPencil} />
                                                    </button>
                                                </div>
                                            )}
                                        </td>
                                        <td className="project-detail-table-cell">
                                            {doc.documentType}
                                        </td>
                                        <td className="project-detail-table-cell">
                                            {formatDateTime(doc.created_at)}
                                        </td>
                                        <td className="project-detail-table-cell">
                                            <div className="project-detail-actions">
                                                <button
                                                    onClick={() => handleDownload(doc.id, doc.documentTitle)}
                                                    className="project-detail-action-button"
                                                    aria-label={`Download document ${doc.documentTitle}`}
                                                >
                                                    Download
                                                </button>
                                                <button
                                                    onClick={() => handleRemoveDocument(doc.id)}
                                                    className="project-detail-action-button project-detail-remove-button"
                                                    aria-label={`Remove document ${doc.documentTitle} from project`}
                                                >
                                                    Remove
                                                </button>
                                                <button
                                                    onClick={() => handleDeleteDocument(doc.id)}
                                                    className="project-detail-action-button project-detail-delete-button"
                                                    aria-label={`Delete document ${doc.documentTitle}`}
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>

                {/* Threads Section */}
                <div className="project-detail-section">
                    <div className="project-detail-section-header">
                        <h2 className="project-detail-section-title">Threads</h2>
                        <div className="project-detail-doc-buttons">
                            {/* Add Existing Thread Dropdown Button */}
                            <div
                                className="project-detail-thread-add-container"
                                ref={threadDropdownRef}
                            >
                                <button
                                    onClick={() => {
                                        fetchAllThreads();
                                        setShowThreadDropdown((prev) => !prev);
                                    }}
                                    className="project-detail-add-existing-button"
                                    aria-label="Add Existing Thread"
                                    aria-haspopup="true"
                                    aria-expanded={showThreadDropdown}
                                >
                                    <FontAwesomeIcon icon={faChevronDown} />
                                </button>
                                {showThreadDropdown && (
                                    <ul className="project-detail-thread-dropdown">
                                        {availableThreads.length === 0 ? (
                                            <li className="project-detail-dropdown-item">
                                                No available threads.
                                            </li>
                                        ) : (
                                            availableThreads.map((thread) => (
                                                <li
                                                    key={thread.id}
                                                    className="project-detail-dropdown-item"
                                                    tabIndex={0}
                                                    onClick={() => handleAddExistingThread(thread.id)}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            handleAddExistingThread(thread.id);
                                                        }
                                                    }}
                                                >
                                                    {thread.threadPrompt}
                                                </li>
                                            ))
                                        )}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                    {projectThreads.length === 0 ? (
                        <p>No threads found for this project.</p>
                    ) : (
                        <table
                            className="project-detail-table"
                            role="table"
                            aria-label="Threads table"
                        >
                            <thead>
                                <tr>
                                    <th
                                        className={`project-detail-table-header ${
                                            isDarkMode
                                                ? 'project-detail-table-header-dark'
                                                : 'project-detail-table-header-light'
                                        }`}
                                    >
                                        Prompt
                                    </th>
                                    <th
                                        className={`project-detail-table-header ${
                                            isDarkMode
                                                ? 'project-detail-table-header-dark'
                                                : 'project-detail-table-header-light'
                                        }`}
                                    >
                                        Type
                                    </th>
                                    <th
                                        className={`project-detail-table-header ${
                                            isDarkMode
                                                ? 'project-detail-table-header-dark'
                                                : 'project-detail-table-header-light'
                                        }`}
                                    >
                                        Created At
                                    </th>
                                    <th
                                        className={`project-detail-table-header ${
                                            isDarkMode
                                                ? 'project-detail-table-header-dark'
                                                : 'project-detail-table-header-light'
                                        }`}
                                    >
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {projectThreads.map((thread) => (
                                    <tr key={thread.id} className="project-detail-table-row">
                                        <td className="project-detail-table-cell">
                                            {editingThreadId === thread.id ? (
                                                <div className="project-detail-rename-container">
                                                    <input
                                                        type="text"
                                                        value={newThreadPrompt}
                                                        onChange={(e) => setNewThreadPrompt(e.target.value)}
                                                        className="rename-input"
                                                        aria-label={`Rename thread ${thread.threadPrompt}`}
                                                    />
                                                    <button
                                                        onClick={() => handleRenameThread(thread.id)}
                                                        className="rename-save-button"
                                                    >
                                                        Save
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            setEditingThreadId(null);
                                                            setNewThreadPrompt('');
                                                        }}
                                                        className="rename-cancel-button"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            ) : (
                                                <div className="project-detail-rename-container">
                                                    {thread.threadPrompt}
                                                    <button
                                                        onClick={() => {
                                                            setEditingThreadId(thread.id);
                                                            setNewThreadPrompt(thread.threadPrompt);
                                                        }}
                                                        className="project-detail-edit-button"
                                                        aria-label={`Rename thread ${thread.threadPrompt}`}
                                                    >
                                                        <FontAwesomeIcon icon={faPencil} />
                                                    </button>
                                                </div>
                                            )}
                                        </td>
                                        <td className="project-detail-table-cell">
                                            {thread.threadType}
                                        </td>
                                        <td className="project-detail-table-cell">
                                            {formatDateTime(thread.created_at)}
                                        </td>
                                        <td className="project-detail-table-cell">
                                            <div className="project-detail-actions project-detail-actions-threads">
                                                <button
                                                    onClick={() => handleViewThread(thread.id)}
                                                    className="project-detail-action-button"
                                                    aria-label={`View thread ${thread.threadPrompt}`}
                                                >
                                                    View
                                                </button>
                                                <button
                                                    onClick={() => handleRemoveThread(thread.id)}
                                                    className="project-detail-action-button project-detail-remove-button"
                                                    aria-label={`Remove thread ${thread.threadPrompt}`}
                                                >
                                                    Remove
                                                </button>
                                                <button
                                                    onClick={() => handleDeleteThread(thread.id)}
                                                    className="project-detail-action-button project-detail-delete-button"
                                                    aria-label={`Delete thread ${thread.threadPrompt}`}
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>

                {/* Go Back Button */}
                <button
                    onClick={handleGoBack}
                    className={`project-detail-back-button ${
                        isDarkMode
                            ? 'project-detail-back-button-dark'
                            : 'project-detail-back-button-light'
                    }`}
                    aria-label="Go Back to Library"
                >
                    <FontAwesomeIcon icon={faArrowLeft} /> Go Back
                </button>

                {/* Add Document Modal */}
                <LibraryModal
                    isOpen={showAddDocumentModal}
                    onClose={() => setShowAddDocumentModal(false)}
                    title="Add Document to Project"
                >
                    <AddDocumentForm
                        projectId={projectId}
                        documentsURL={documentsURL}
                        onSuccess={() => {
                            // Re-fetch documents after adding
                            fetchProjectDocuments();
                            // Also re-fetch allDocuments to ensure dropdown is updated
                            fetchAllDocuments();
                            setShowAddDocumentModal(false);
                        }}
                    />
                </LibraryModal>
            </main>
        </div>
    );
};

export default ProjectDetail;
