// Navbar.tsx

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faCog,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import './style/Navbar.css';
import { useDefaultProjectContext } from '../ContextStore'; // Import the custom hook
import { Project } from '../types'; // Ensure Project interface is defined in '../types'

interface NavbarProps {
  setSection: (section: string) => void;
  userEmail: string;
  isDarkMode: boolean;
  projects: Project[]; // A list of user's projects
  setCurrentProjectId: (projectId: string) => void; // A function to set the current projectId
  selectedProjectId: string | null; // The currently selected project's ID
}

const Navbar: React.FC<NavbarProps> = ({
  setSection,
  userEmail,
  isDarkMode,
  projects,
  setCurrentProjectId,
  selectedProjectId
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Consume DefaultProjectContext using the custom hook
  const { defaultProjectName } = useDefaultProjectContext();

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleProjectClick = (projectId: string) => {
    // Set the current project in App state
    setCurrentProjectId(projectId);
    // Navigate to ProjectDashboard for that project
    setSection('ProjectDashboard');
    setIsMenuOpen(false);
  };

  const handleNewProjectClick = () => {
    setSection('NewProject');
    setIsMenuOpen(false);
  };

  return (
    <div className={`navbar ${isDarkMode ? 'dark' : 'light'}`}>
      <button
        className="navbar-logo-button"
        onClick={() => setSection('ProjectDashboard')}
        aria-label="Navigate to Project Dashboard"
      >
        <img
          src="/InnoScaleLogo1Cropped.png"
          alt="InnoScale company logo"
          className="navbar-logo"
        />
      </button>

      {isSmallScreen ? (
        <>
          {/* Mobile (small screen) Menu Toggle Button */}
          <button
            className="navbar-menu-toggle-button"
            onClick={handleMenuToggle}
            aria-label="Toggle menu"
          >
            <FontAwesomeIcon icon={faBars} className="navbar-icon" />
          </button>

          {isMenuOpen && (
            <div className={`navbar-menu open ${isDarkMode ? 'dark' : 'light'}`}>
              {/* Projects Header */}
              <div className="navbar-projects-header">
                Projects
                <button
                  className="navbar-new-project-button"
                  onClick={handleNewProjectClick}
                  aria-label="Create a new project"
                >
                  <FontAwesomeIcon icon={faPlus} className="navbar-new-project-icon" />
                </button>
              </div>
              {projects.length > 0 ? (
                projects.map((project) => {
                  const isActive = project.id === selectedProjectId;
                  return (
                    <button
                      key={project.id}
                      onClick={() => handleProjectClick(project.id)}
                      className={`navbar-project-button ${
                        isActive ? 'active' : ''
                      }`}
                    >
                      {project.name}
                    </button>
                  );
                })
              ) : (
                <button className="navbar-project-button" disabled>
                  No projects found.
                </button>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          {/* Larger Screens: Permanent Sidebar */}
          <div className={`navbar-menu ${isDarkMode ? 'dark' : 'light'}`}>
            {/* Projects Header */}
            <div className="navbar-projects-header-static">
              Projects
              <button
                className="navbar-new-project-button"
                onClick={handleNewProjectClick}
                aria-label="Create a new project"
              >
                <FontAwesomeIcon icon={faPlus} className="navbar-new-project-icon" />
              </button>
            </div>

            {projects.length > 0 ? (
              projects.map((project) => {
                const isActive = project.id === selectedProjectId;
                return (
                  <button
                    key={project.id}
                    onClick={() => handleProjectClick(project.id)}
                    className={`navbar-project-button ${
                      isActive ? 'active' : ''
                    }`}
                  >
                    {project.name}
                  </button>
                );
              })
            ) : (
              <button className="navbar-project-button" disabled>
                No projects found.
              </button>
            )}
          </div>

          {/* Display user's email and default project on the navbar */}
          <div className="navbar-email" onClick={() => setSection('Settings')}>
            <div className="navbar-email-info">
              <span className="navbar-email-text">{userEmail}</span>
              {defaultProjectName && (
                <span className="navbar-default-project-name">{defaultProjectName}</span>
              )}
            </div>
            <FontAwesomeIcon icon={faCog} className="navbar-settings-icon" />
          </div>
        </>
      )}
    </div>
  );
};

export default Navbar;
