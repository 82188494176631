// App.tsx

import React, { useState, useEffect, useRef, useCallback } from 'react';
import Navbar from './components/Navbar';
import TechScouting from './components/TechScouting';
import StakeholderIdentification from './components/StakeholderIdentification';
import ProjectSupport from './components/ProjectSupport';
import SignIn from './components/SignIn';
import MOUGeneration from './components/MOUGeneration';
import SiteMap from './components/SiteMap';
import Library from './components/Library';
import ATODocuments from './components/ATODocuments';
import ATOAssistant from './components/ATOAssistant';
import SignUp from './components/SignUp';
import Settings from './components/Settings';
import CompanyDetails from './components/CompanyDetails';
import Proposals from './components/Proposals';
import NewProject from './components/NewProject';
import ProjectDashboard from './components/ProjectDashboard';
import {
  TechScoutingContext,
  StakeholderIdentificationContext,
  DefaultProjectContext,
} from './ContextStore';
import { StakeholderData, CompanyData, Project, ProjectData } from './types';
import { createPusherInstance } from './utils/pusherConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pusher, { Channel } from 'pusher-js';
import axiosRequest from './utils/axiosRequest';
import './App.css';

const App: React.FC = () => {
  const [section, setSection] = useState<string>('TechScouting');
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isInProposalsGroup, setIsInProposalsGroup] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const [techResponse, setTechResponse] = useState<any>(null);
  const [selectedCompany, setSelectedCompany] = useState<CompanyData | null>(null);
  const [stakeholderResponse, setStakeholderResponse] = useState<StakeholderData[]>([]);

  // Pusher refs
  const pusherRef = useRef<Pusher | null>(null);
  const channelRef = useRef<Channel | null>(null);

  // Default Project Context State
  const [defaultProjectName, setDefaultProjectName] = useState<string>('');
  const [defaultProjectId, setDefaultProjectId] = useState<string>('');
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(null);
  const [selectedLibraryProjectId, setSelectedLibraryProjectId] = useState<string | null>(null);

  // Dark mode state
  const [isDarkMode, setIsDarkMode] = useState<boolean>(
    () => localStorage.getItem('darkMode') !== 'false'
  );

  const authURL = process.env.REACT_APP_SIGNIN_API_URL;
  if (!authURL) {
    throw new Error('REACT_APP_SIGNIN_API_URL is not defined');
  }

  const projectsURL = process.env.REACT_APP_PROJECTS_API_URL;
  if (!projectsURL) {
    throw new Error('REACT_APP_PROJECTS_API_URL is not defined');
  }

  const [projects, setProjects] = useState<Project[]>([]);

  useEffect(() => {
    const theme = isDarkMode ? 'dark-mode' : 'light-mode';
    document.body.classList.add(theme);
    return () => {
      document.body.classList.remove('dark-mode', 'light-mode');
    };
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    const newMode = !isDarkMode;
    setIsDarkMode(newMode);
    localStorage.setItem('darkMode', newMode ? 'true' : 'false');

    if (newMode) {
      document.body.classList.remove('light-mode');
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
      document.body.classList.add('light-mode');
    }
  };

  // Initialize Pusher when userId is set
  useEffect(() => {
    if (userId && isAuthenticated) {
      console.log('Initializing Pusher');
      initializePusher();
    }
    return () => {
      cleanupPusher();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, isAuthenticated]);

  const initializePusher = () => {
    if (pusherRef.current) {
      console.log('Pusher already initialized.');
      return;
    }

    if (!userId) {
      console.error('User ID not found for Pusher.');
      return;
    }

    const pusher = createPusherInstance();
    pusherRef.current = pusher;

    console.log(`Subscribing to private channel for user ${userId}`);
    const channel = pusher.subscribe(`private-user-${userId}`);
    channelRef.current = channel;

    channel.bind('document-event', handleDocumentEvent);
    channel.bind('pusher:subscription_error', handleSubscriptionError);

    console.log('Pusher subscription initialized.');
  };

  const handleDocumentEvent = (data: any) => {
    console.log('Received document event:', data);
    if (data.message === 'document_complete') {
      const { document_url } = data;
      toast.success('Generated Document added to Library!', {
        position: 'top-right',
        autoClose: 5000,
        theme: 'dark',
        icon: (
          <img
            src="/LogoThumbnailWhite.png"
            alt="Logo Thumbnail"
            className="toast-custom-icon"
          />
        ),
        onClick: () => window.open(document_url, '_blank'),
        className: 'custom-toast-clickable',
      });
    }
  };

  const handleSubscriptionError = (status: number) => {
    console.error('Pusher subscription error:', status);
  };

  const cleanupPusher = () => {
    if (channelRef.current) {
      console.log('Cleaning up Pusher channel subscription');
      channelRef.current.unbind_all();
      channelRef.current.unsubscribe();
      channelRef.current = null;
    }
    if (pusherRef.current) {
      console.log('Disconnecting Pusher instance');
      pusherRef.current.disconnect();
      pusherRef.current = null;
    }
  };

  /**
   * Handle Sign In
   * Accept user info and userProjects directly and determine where to route the user:
   * - If the user has no projects => go to NewProject.
   * - If the user has projects => go to ProjectDashboard (for default project).
   */
  const handleSignIn = (
    email: string,
    uid: string,
    groups: string[],
    userProjects: Project[]
  ) => {
    setIsAuthenticated(true);
    setUserEmail(email);
    setUserId(uid);
    setIsInProposalsGroup(Array.isArray(groups) && groups.includes('proposals'));
    setProjects(userProjects);

    // If user has projects, find or set default
    if (userProjects && userProjects.length > 0) {
      const defaultProj = userProjects.find((proj: Project) => proj.is_default);
      if (defaultProj) {
        setDefaultProjectName(defaultProj.name);
        setDefaultProjectId(defaultProj.id);
        setSelectedProjectId(defaultProj.id);
      }
      // Take user to Project Dashboard
      setSection('ProjectDashboard');
    } else {
      // No projects at all => take user to NewProject
      setSection('NewProject');
    }
  };

  const handleSignOut = async () => {
    cleanupPusher();
    try {
      await axiosRequest(`${authURL}signout/`, 'post');
    } catch (error) {
      console.error('Error during sign out:', error);
    }
    setIsAuthenticated(false);
    setSection('SignIn');
    setSelectedCompany(null);
    setUserEmail('');
    setUserId('');
    setIsInProposalsGroup(false);
    setDefaultProjectName('');
    setDefaultProjectId('');
    setSelectedProjectId(null);
    setProjects([]);
  };

  useEffect(() => {
    if (section === 'TechScouting' && selectedCompany !== null) {
      setSection('CompanyDetails');
    }
  }, [section, selectedCompany]);

  // Define the removeProjectFromList callback
  const removeProjectFromList = (projectId: string) => {
    setProjects((prevProjects) => prevProjects.filter((proj) => proj.id !== projectId));
    // If the deleted project was the selected one, reset the selectedProjectId
    if (selectedProjectId === projectId) {
      setSelectedProjectId(null);
      setSection('TechScouting'); // Navigate to a default section
      toast.info('Project has been deleted and you have been redirected.', {
        position: 'top-right',
        autoClose: 5000,
        theme: 'dark',
      });
    }
  };

  const renderSection = () => {
    console.log('Current section:', section);

    if (isInProposalsGroup) {
      switch (section) {
        case 'Proposals':
          return <Proposals isDarkMode={isDarkMode} setSection={setSection} />;
        case 'Documents':
          return (
            <Library
              setSection={setSection}
              isDarkMode={isDarkMode}
              isInProposalsGroup={isInProposalsGroup}
              selectedLibraryProjectId={selectedLibraryProjectId}
              setSelectedLibraryProjectId={setSelectedLibraryProjectId}
            />
          );
        case 'Settings':
          return (
            <Settings
              userEmail={userEmail}
              handleSignOut={handleSignOut}
              isDarkMode={isDarkMode}
              toggleDarkMode={toggleDarkMode}
            />
          );
        case 'ProjectDashboard':
          return (
            <ProjectDashboard
              isDarkMode={isDarkMode}
              projectId={selectedProjectId}
              setSection={setSection}
              setSelectedLibraryProjectId={setSelectedLibraryProjectId}
              updateProjectInList={(updatedProject: ProjectData) => {
                setProjects((prevProjects) =>
                  prevProjects.map((proj) =>
                    proj.id === updatedProject.id ? { ...proj, name: updatedProject.name } : proj
                  )
                );
              }}
              removeProjectFromList={removeProjectFromList} // Pass the new callback
              key={selectedProjectId}
            />
          );
        default:
          return <Proposals isDarkMode={isDarkMode} setSection={setSection} />;
      }
    }

    switch (section) {
      case 'ATOAssistant':
        return <ATOAssistant setSection={setSection} isDarkMode={isDarkMode} />;
      case 'TechScouting':
        return <TechScouting isDarkMode={isDarkMode} setSection={setSection} />;
      case 'Proposals':
        return <Proposals isDarkMode={isDarkMode} setSection={setSection} />;
      case 'StakeholderIdentification':
        return <StakeholderIdentification isDarkMode={isDarkMode} />;
      case 'CompanyDetails':
        return <CompanyDetails isDarkMode={isDarkMode} setSection={setSection} />;
      case 'ProjectSupport':
        return <ProjectSupport isDarkMode={isDarkMode} />;
      case 'MOUGeneration':
        return <MOUGeneration isDarkMode={isDarkMode} />;
      case 'Documents':
        return (
          <Library
            setSection={setSection}
            isDarkMode={isDarkMode}
            isInProposalsGroup={isInProposalsGroup}
            selectedLibraryProjectId={selectedLibraryProjectId}
            setSelectedLibraryProjectId={setSelectedLibraryProjectId}
          />
        );
      case 'ATODocuments':
        return <ATODocuments setSection={setSection} isDarkMode={isDarkMode} />;
      case 'Settings':
        return (
          <Settings
            userEmail={userEmail}
            handleSignOut={handleSignOut}
            isDarkMode={isDarkMode}
            toggleDarkMode={toggleDarkMode}
          />
        );
      case 'NewProject':
        return (
          <NewProject
            setSection={setSection}
            setSelectedProjectId={setSelectedProjectId}
            isDarkMode={isDarkMode}
            fetchProjects={async () => {
              // After creating a project, fetch projects and update state
              try {
                const response = await axiosRequest(`${projectsURL}`, 'get');
                const sortedProjects: Project[] = response.data.sort(
                  (a: Project, b: Project) =>
                    new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
                );
                setProjects(sortedProjects);

                // Check if a new default project is set
                const defaultProj = sortedProjects.find((proj: Project) => proj.is_default);
                if (defaultProj) {
                  setDefaultProjectName(defaultProj.name);
                  setDefaultProjectId(defaultProj.id);
                  setSelectedProjectId(defaultProj.id);
                }
              } catch (error) {
                console.error('Error fetching projects after new project created:', error);
              }
            }}
          />
        );
      case 'ProjectDashboard':
        return (
          <ProjectDashboard
            isDarkMode={isDarkMode}
            projectId={selectedProjectId}
            setSection={setSection}
            setSelectedLibraryProjectId={setSelectedLibraryProjectId}
            updateProjectInList={(updatedProject: ProjectData) => {
              setProjects((prevProjects) =>
                prevProjects.map((proj) =>
                  proj.id === updatedProject.id ? { ...proj, name: updatedProject.name } : proj
                )
              );
            }}
            removeProjectFromList={removeProjectFromList} // Pass the new callback
            key={selectedProjectId}
          />
        );
      default:
        return <TechScouting isDarkMode={isDarkMode} setSection={setSection} />;
    }
  };

  if (!isAuthenticated) {
    return section === 'SignUp' ? (
      <SignUp onSignIn={handleSignIn} setSection={setSection} />
    ) : (
      <SignIn onSignIn={handleSignIn} setSection={setSection} />
    );
  }

  return (
    <div className="App">
      <a
        href="#main-content"
        className="skip-link"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            const mainContent = document.getElementById('main-content');
            if (mainContent) {
              mainContent.focus();
            }
          }
        }}
      >
        Skip to main content
      </a>
      <DefaultProjectContext.Provider
        value={{
          defaultProjectId,
          setDefaultProjectId,
          defaultProjectName,
          setDefaultProjectName,
        }}
      >
        <Navbar
          setSection={setSection}
          userEmail={userEmail}
          isDarkMode={isDarkMode}
          projects={projects}
          setCurrentProjectId={setSelectedProjectId}
          selectedProjectId={selectedProjectId}
        />
        <div className="content" id="main-content" tabIndex={-1}>
          <TechScoutingContext.Provider
            value={{ techResponse, setTechResponse, selectedCompany, setSelectedCompany }}
          >
            <StakeholderIdentificationContext.Provider
              value={{ stakeholderResponse, setStakeholderResponse }}
            >
              {renderSection()}
            </StakeholderIdentificationContext.Provider>
          </TechScoutingContext.Provider>
        </div>
        <SiteMap
          setSection={setSection}
          isInProposalsGroup={isInProposalsGroup}
          isDarkMode={isDarkMode}
        />
        <ToastContainer />
      </DefaultProjectContext.Provider>
    </div>
  );
};

export default App;
